import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/user.actions";

import Header from "./Header1";
import HeaderItem from "./HeaderItem";
import LinkItem from "./LinkItem";
import ProfileInfo from "./ProfileInfo";
// import NotificationIcon from "./NotificationIcon";
import Grow from "./Grow";
import ReactTooltip from "react-tooltip";
import { colors } from "../themes/base";

const DefaultHeader = (props) => {
  const { pathname } = props.location;

  return (
    <Header height={6.8}>
      {/* <HeaderItem
        isActive={
          (pathname.includes("/video") || pathname.includes("/audio")) &&
          !pathname.includes("videosearch")
        }
      >
        <LinkItem
          isActive={
            (pathname.includes("/video") || pathname.includes("/audio")) &&
            !pathname.includes("videosearch")
          }
          src="/video/raw/videos"
          label="Redaction"
        />
      </HeaderItem> */}

      <HeaderItem isActive={pathname.includes("/facesearch")}>
        <LinkItem
          isActive={pathname.includes("/facesearch")}
          src="/facesearch"
          label="Historic Face Search"
        />
      </HeaderItem>

      {/* <HeaderItem isActive={pathname.includes("/investigator")}>
        <LinkItem
          isActive={pathname.includes("/investigator")}
          src="/investigator/cases"
          label="Investigator"
        />
      </HeaderItem> */}

      {/* <HeaderItem isActive={pathname.includes("/imagesearch")}>
        <LinkItem
          isActive={pathname.includes("/imagesearch")}
          src="/imagesearch/cases"
          label="Image Search"
        />
      </HeaderItem>

      <HeaderItem isActive={pathname.includes("/videosearch")}>
        <LinkItem
          isActive={pathname.includes("/videosearch")}
          src="/videosearch/cases"
          label="Video Search"
        />
      </HeaderItem> */}

      <Grow />

      {props.user && props.user.email === process.env.REACT_APP_ADMIN && (
        <HeaderItem>
          <Link
            style={{ color: colors.brand, textDecoration: "none" }}
            to="/users"
          >
            Manage Users
          </Link>
        </HeaderItem>
      )}

      {/* <HeaderItem>
        <NotificationIcon unreadNotifications onClick={() => {}} />
      </HeaderItem> */}

      <HeaderItem>
        {/* eslint-disable-next-line */}
        <a
          style={{ background: "none", border: "none", cursor: "pointer" }}
          data-tip="React-tooltip"
          alt="dropdown"
          data-event="click focus"
          data-for="profile-tooltip"
        >
          <ProfileInfo
            avatar={props.user.profileImage}
            username={props.user.fullName || ""}
          />
        </a>
        <ReactTooltip
          id="profile-tooltip"
          className="tooltip"
          place="bottom"
          type="light"
          effect="solid"
          globalEventOff="click"
        >
          <div className="dropdown">
            <Link to={{ pathname: "/profile/edit" }} className="item profile">
              Profile
            </Link>
            {/* <hr />
            <div className="item settings">Settings</div> */}
            <hr />
            <div
              className="item logout"
              onClick={props.logoutUser}
              style={{ color: "#FF3B30" }}
            >
              Logout
            </div>
          </div>
        </ReactTooltip>
      </HeaderItem>
    </Header>
  );
};

const mapStateToProps = ({ user }) => ({
  isAuthenticated: user.isAuthenticated,
  user: user.user,
});

export default connect(mapStateToProps, { logoutUser })(DefaultHeader);
