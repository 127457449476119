import React from "react";

import Sidebar from "./Sidebar1";
import SidebarItem from "./SidebarItem";
import LinkItem from "./LinkItem";
import MainIcon from "./icons/main.png";
import HomeIcon from "./icons/home-white.png";
import ManageUsersIcon from "./icons/manageUsers.png";
import SearchCaseIcon from "./icons/search_case.png";
import AddVideoIcon from "./icons/add-video-white.png";
import RawVideosIcon from "./icons/video-white.png";
import RedactedIcon from "./icons/redacted-video-white.png";
import AddAudioIcon from "./icons/add-audio-white.png";
import RawAudioIcon from "./icons/audio-white.png";
import RedactedAudioIcon from "./icons/redacted-audio-white.png";

import TeamIcon from "./icons/team.png";
// import DemographicIcon from "./icons/demographic.png";

const HistoricFaceSearchMenu = ({ pathname }) => (
  <>
    <SidebarItem isActive={pathname === "/facesearch"}>
      <LinkItem src="/facesearch" label="Home" icon={HomeIcon} />
    </SidebarItem>
    <SidebarItem isActive={pathname.includes("/facesearch/watchlist")}>
      <LinkItem
        src="/facesearch/watchlist"
        label="Watchlist"
        icon={ManageUsersIcon}
      />
    </SidebarItem>
    <SidebarItem isActive={pathname.includes("/facesearch/cases")}>
      <LinkItem src="/facesearch/cases" label="Cases" icon={AddVideoIcon} />
    </SidebarItem>
    <SidebarItem isActive={pathname.includes("/facesearch/searchcase")}>
      <LinkItem
        src="/facesearch/searchcase"
        label="Search Cases"
        icon={SearchCaseIcon}
      />
    </SidebarItem>
    {/* <SidebarItem isActive={pathname.includes("/facesearch/group")}>
      <LinkItem icon={TeamIcon} src="/facesearch/group" label="Group Faces" />
    </SidebarItem> */}
    {/* <SidebarItem isActive={pathname.includes("/facesearch/demograph")}>
      <LinkItem
        icon={DemographicIcon}
        src="/facesearch/demograph"
        label="Demographs"
      />
    </SidebarItem> */}
  </>
);

const RedactionMenu = ({ pathname }) => (
  <>
    <SidebarItem isActive={pathname.includes("/video/add/video")}>
      <LinkItem src="/video/add/video" label="Add Video" icon={AddVideoIcon} />
    </SidebarItem>
    <SidebarItem isActive={pathname.includes("/video/raw/videos")}>
      <LinkItem
        src="/video/raw/videos"
        label="Raw Videos"
        icon={RawVideosIcon}
      />
    </SidebarItem>
    <SidebarItem isActive={pathname.includes("/video/redacted/videos")}>
      <LinkItem
        src="/video/redacted/videos"
        label="Redacted Videos"
        icon={RedactedIcon}
      />
    </SidebarItem>
    <div style={{ height: "3rem" }} />
    <SidebarItem isActive={pathname.includes("/video/add/audio")}>
      <LinkItem src="/video/add/audio" label="Add Audio" icon={AddAudioIcon} />
    </SidebarItem>
    <SidebarItem isActive={pathname.includes("/video/raw/audio")}>
      <LinkItem src="/video/raw/audio" label="Raw Audio" icon={RawAudioIcon} />
    </SidebarItem>
    <SidebarItem isActive={pathname.includes("/video/redacted/audio")}>
      <LinkItem
        src="/video/redacted/audio"
        label="Redacted Audio"
        icon={RedactedAudioIcon}
      />
    </SidebarItem>
  </>
);

const InvestigatorMenu = ({ pathname }) => (
  <>
    <SidebarItem isActive={pathname.includes("/investigator/create")}>
      <LinkItem
        src="/investigator/create"
        label="Create Cases"
        icon={AddVideoIcon}
      />
    </SidebarItem>
    <SidebarItem isActive={pathname.includes("/investigator/cases")}>
      <LinkItem src="/investigator/cases" label="Cases" icon={RawVideosIcon} />
    </SidebarItem>
  </>
);

const ImageSearchMenu = ({ pathname }) => (
  <>
    <SidebarItem isActive={pathname.includes("/imagesearch/create")}>
      <LinkItem
        src="/imagesearch/create"
        label="Create Cases"
        icon={AddVideoIcon}
      />
    </SidebarItem>

    <SidebarItem isActive={pathname.includes("/imagesearch/cases")}>
      <LinkItem src="/imagesearch/cases" label="Cases" icon={RawVideosIcon} />
    </SidebarItem>
  </>
);

const VideoSearchMenu = ({ pathname }) => (
  <>
    <SidebarItem isActive={pathname.includes("/videosearch/create")}>
      <LinkItem
        src="/videosearch/create"
        label="Create Cases"
        icon={AddVideoIcon}
      />
    </SidebarItem>

    <SidebarItem isActive={pathname.includes("/videosearch/cases")}>
      <LinkItem src="/videosearch/cases" label="Cases" icon={RawVideosIcon} />
    </SidebarItem>
  </>
);

const DefaultMenu = ({ pathname }) => (
  <>
    <SidebarItem>
      <LinkItem src="/video" label="Home" icon={HomeIcon} disabled />
    </SidebarItem>
  </>
);

const DefaultSidebar = (props) => {
  const { pathname } = props.location;
  return (
    <Sidebar>
      <SidebarItem
        style={{
          opacity: 1,
          background:
            "linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))",
          height: "6.8rem",
          paddingTop: "1.6rem",
        }}
      >
        <LinkItem src="/" label="" icon={MainIcon} disabled iconWidth="85px" />
      </SidebarItem>

      {pathname.includes("/facesearch") ? (
        <HistoricFaceSearchMenu pathname={pathname} />
      ) : null}

      {(pathname.includes("/video") || pathname.includes("/audio")) &&
      !pathname.includes("videosearch") ? (
        <RedactionMenu pathname={pathname} />
      ) : null}

      {pathname.includes("/investigator") ? (
        <InvestigatorMenu pathname={pathname} />
      ) : null}

      {pathname.includes("/imagesearch") ? (
        <ImageSearchMenu pathname={pathname} />
      ) : null}

      {pathname.includes("/videosearch") ? (
        <VideoSearchMenu pathname={pathname} />
      ) : null}

      {pathname.includes("/profile") ? (
        <DefaultMenu pathname={pathname} />
      ) : null}
    </Sidebar>
  );
};

export default DefaultSidebar;
