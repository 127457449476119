import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "../themes/base";
import texture from "../../assets/img/ic_dot-texture.svg";
import logo from "../../assets/img/shutout.ai-logo.png";

const BackgroundWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: ${colors.brand};
  position: relative;
`;

// const Heading = styled.div`
//   height: 6.4rem;
//   width: 42rem;
//   color: #ffffff;
//   font-family: ${(props) => props.fontFamily};
//   font-size: 2.4rem;
//   line-height: 3.2rem;
//   left: 8.2rem;
//   top: 15.4rem;
//   position: absolute;
// `;

const Pattern = styled.div`
  background-image: url(${texture});
  height: 16.1rem;
  width: 16.1rem;
  position: absolute;
  ${(props) => (props.top ? `top: ${props.top}rem` : "")};
  ${(props) => (props.bottom ? `bottom: ${props.bottom}rem` : "")};
  ${(props) => (props.left ? `left: ${props.left}rem` : "")};
  ${(props) => (props.right ? `right: ${props.right}rem` : "")};
`;

const Logo = styled.div`
  height: 11rem;
  width: 24rem !important;
  position: absolute;
  top: ${(props) => `${props.top}rem`};
  left: ${(props) => `${props.left}rem`};
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
`;

const Background = (props) => {
  return (
    <BackgroundWrapper {...props}>
      <Logo top="5.6" left="8.1" />
      {/* <Heading {...props}>Automatic Smart Redaction (ASR)</Heading> */}
      <Pattern top="21.8" right="10.2" />
      <Pattern bottom="10.2" right="22.2" />
    </BackgroundWrapper>
  );
};

export default Background;

Background.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  fontFamily: PropTypes.string,
};

Background.defaultProps = {
  fontFamily: '"Muli", sans-serif',
};
