import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  successNotification,
  warningNotification,
  errorNotification,
} from "../../actions";

import {
  fetchVideos,
  deleteVideo,
  getIframeURL,
} from "../../actions/redaction.actions.js";
import {
  encodeVideo,
  setEncodeModal,
  setEncodeVideoId,
} from "../../actions/encode.actions";
import ImageCard from "../../components/VideoCard/ImageCard";
import DeleteVideoModal from "../../components/Modal/DeleteVideoModal";
import EncodeVideoModal from "../../components/Modal/EncodeVideoModal";
import PlaceholderRawCard from "../../components/Placeholder/PlaceholderRawCard";
import { ReactComponent as EmptySVG } from "../../assets/img/empty.svg";
import Button from "../../components/common/Button";
import { colors } from "../../components/themes/base";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
  margin-left: 5rem;
  margin-right: 3rem;
  padding-bottom: 3rem;

  @media only screen and (min-width: 680px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1020px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

const handleDelete = async (props, videoID, setDeleteStatus, setvideoId) => {
  const data = await props.deleteVideo(videoID);
  if (data.success) {
    setvideoId(null);
    props.fetchVideos();
    props.successNotification({
      title: "Video deleted successfully!",
      autoDismiss: 1,
    });
  } else {
    setDeleteStatus(false);
    props.errorNotification({
      title: "Unable to delete the video",
      autoDismiss: 1,
    });
  }
};

const StyledNotFound = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    max-width: 30rem;
    transform: translateY(-20rem);
  }

  .message {
    transform: translateY(-45rem);
    text-align: center;

    h4 {
      font-size: 3rem;
      font-weight: bold;
      margin: 0;
    }

    p {
      font-size: 1.8rem;
      color: grey;
    }

    button {
      margin: 0 auto;
      background: ${colors.brand};
      font-size: 1.6rem;
      box-shadow: 0 1rem 2rem 0 rgba(124, 160, 73, 0.15);
      color: #ffffff;
      padding: 1rem 2rem;
      text-transform: uppercase;
    }
  }
`;

const NoVideosFound = (props) => (
  <StyledNotFound>
    <EmptySVG />
    <div className="message">
      <h4>No Videos</h4>
      <p>When you upload videos. They will appear here.</p>
      <Button
        height={"34px"}
        onClick={() => props.history.push("/video/add/video")}
      >
        Upload
      </Button>
    </div>
  </StyledNotFound>
);

const PlaceHolderCards = () => (
  <Grid>
    {Array.from(Array(12).keys()).map((_, i) => (
      <PlaceholderRawCard key={i} />
    ))}
  </Grid>
);

const RawVideos = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [videoId, setvideoId] = useState(null);
  const [videoName, setvideoName] = useState("");
  const [deleteStatus, setDeleteStatus] = useState(false);

  if (props.rawVideos === null) {
    return <NoVideosFound history={props.history} />;
  } else if (props.rawVideos.length === 0) {
    const data = props.fetchVideos();
    data.then((result) => {
      if (!result) {
        props.errorNotification({
          title: "Unable to fetch videos",
          autoDismiss: 1,
        });
      }
    });
    return (
      <div>
        <div
          style={{
            color: "#142945",
            fontFamily: "Muli",
            fontSize: "2.2rem",
            fontWeight: "700",
            letterSpacing: "0.18px",
            margin: "2.5rem 5rem",
          }}
        >
          Raw Videos
        </div>
        <PlaceHolderCards />
      </div>
    );
  } else {
    return (
      <div>
        <DeleteVideoModal
          isVisible={deleteModal}
          videoName={videoName}
          handleCancel={() => setDeleteModal(false)}
          handleDelete={() => {
            setDeleteStatus(true);
            setDeleteModal(false);
            handleDelete(props, videoId, setDeleteStatus, setvideoId);
          }}
        />
        <EncodeVideoModal
          {...props}
          isVisible={props.isEncodeModalVisible}
          handleCancel={() => props.setEncodeModal(false)}
          handleEncode={() => {
            props.encodeVideo(props.encodeVideoId);
          }}
        />
        <div
          style={{
            color: "#142945",
            fontFamily: "Muli",
            fontSize: "2.2rem",
            fontWeight: "700",
            letterSpacing: "0.18px",
            margin: "2.5rem 5rem",
          }}
        >
          Raw Videos
        </div>
        <Grid>
          {props.rawVideos.map((video) => {
            return (
              <ImageCard
                key={video._id}
                deleteStatus={video._id === videoId ? deleteStatus : false}
                videoURL={video.url}
                videoTitle={video.name}
                poster={video.thumbURL}
                timestamp={video.last_modified}
                handleRedact={() =>
                  props.history.push(`/video/iframe/${video._id.toString()}`)
                }
                handleDelete={() => {
                  setvideoId(video._id.toString());
                  setvideoName(video.name);
                  setDeleteModal(true);
                  return;
                }}
                handleEncode={() => {
                  props.setEncodeVideoId(video._id.toString());
                  props.setEncodeModal(true);
                  return;
                }}
                href={video.url}
              />
            );
          })}
        </Grid>
      </div>
    );
  }
};

RawVideos.propTypes = {
  rawVideos: PropTypes.arrayOf(
    PropTypes.shape({
      videoURL: PropTypes.string,
      poster: PropTypes.string,
      videoTitle: PropTypes.string,
      timestamp: PropTypes.string,
      handleRedact: PropTypes.func,
      handleSave: PropTypes.func,
      handleEncode: PropTypes.func,
      handleDelete: PropTypes.func,
    })
  ),
};

RawVideos.defaultProps = {};

const mapStateToProps = (state) => ({
  rawVideos: state.redaction.rawVideos,
  encoding: state.encode.isEncoding,
  encodingProgress: state.encode.encodingProgress,
  isEncodeModalVisible: state.encode.isEncodeModalVisible,
  encodeVideoId: state.encode.encodeVideoId,
});

export default connect(mapStateToProps, {
  fetchVideos,
  deleteVideo,
  encodeVideo,
  getIframeURL,
  successNotification,
  warningNotification,
  errorNotification,
  setEncodeModal,
  setEncodeVideoId,
})(RawVideos);
