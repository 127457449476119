import IC_Banner from "../../assets/icons/ic_banner.jpg";
import IC_Triangle from "../../assets/icons/ic_triangle.png";
import IC_Pentagon from "../../assets/icons/ic_pentagon.png";
import IC_Star from "../../assets/icons/ic_star.png";
import IC_Delete from "../../assets/icons/ic_delete.svg";
import IC_Add_Layer from "../../assets/icons/ic_add_layer.png";
import IC_Video from "../../assets/icons/ic_video.png";
import IC_Video_Blue from "../../assets/icons/ic_video-blue.png";
import IC_Color_Correction from "../../assets/icons/ic_color_correction.png";
import IC_Color_Correction_Blue from "../../assets/icons/ic_color_correction-blue.png";
import IC_Contrast from "../../assets/icons/ic_contrast.png";
import IC_Contrast_Blue from "../../assets/icons/ic_contrast-blue.png";
import IC_Effects from "../../assets/icons/ic_effects.png";
import IC_Effects_Blue from "../../assets/icons/ic_effects-blue.png";
import IC_Text from "../../assets/icons/ic_text.png";
import IC_Text_Blue from "../../assets/icons/ic_text-blue.png";
import IC_Draw from "../../assets/icons/ic_draw.png";
import IC_Draw_Blue from "../../assets/icons/ic_draw-blue.png";
import IC_Shape from "../../assets/icons/ic_shape.png";
import IC_Shape_Blue from "../../assets/icons/ic_shape-blue.png";
import IC_Speed from "../../assets/icons/ic_speed.png";
import IC_Speed_Blue from "../../assets/icons/ic_speed-blue.png";
import IC_EyeOpen from "../../assets/icons/eye_open.png";
import IC_EyeClose from "../../assets/icons/eye_close.png";
import IC_DeleteIcon from "../../assets/icons/rubbish.png";
import IC_AudioSectionPng from "../../assets/icons/audio-section.png";
import IC_VideoSectionPng from "../../assets/icons/video-section.png";
import IC_Tick from "../../assets/icons/ic_tick.svg";
import IC_Cancel from "../../assets/icons/ic_remove.svg";
import IC_Audio from "../../assets/icons/ic_audio.png";
import IC_Audio_Blue from "../../assets/icons/ic_audio-blue.png";
import IC_Magnify from "../../assets/icons/ic_magnify.png";
import IC_Magnify_Blue from "../../assets/icons/ic_magnify-blue.png";
import IC_Printer from "../../assets/icons/ic_printer.png";
import IC_Plus from "../../assets/icons/ic_screenshot.svg";
import IC_Bin from "../../assets/icons/ic_bin.png";
import IC_Keyboard from "../../assets/icons/ic_keyboard.png";
import IC_KeyboardWhite from "../../assets/icons/ic_keyboard_white.png";
import IC_RightArrow from "../../assets/icons/ic_right-arrow.png";
import IC_LeftArrow from "../../assets/icons/ic_left-arrow.png";
import IC_Undo from "../../assets/icons/ic_undo.svg";
import IC_Redo from "../../assets/icons/ic_redo.svg";
import IC_Hamburger from "../../assets/icons/ic_hamburger.png";
import IC_Add_Comment from "../../assets/icons/ic_chat.svg";
import IC_BLue_Tick from "../../assets/icons/ic_tick.svg";
import Suspect_Icon_Dark from "../../assets/icons/suspect-icon-dark.png";
import IC_Edit from "../../assets/icons/ic_edits.svg";
import IC_Share from "../../assets/icons/ic_share.svg";

export const colors = {
  brand: "#133a7b",
  primary: "#133a7b",
  brand1: "#0c2755",
  button: "#133a7b",
  secondary: "#142945",
  tertiary: "#8994A2",
  s_light: "rgba(20,41,69,0.5)", // #142945 with opacity 0.5
  s_verylight: "rgba(20,41,69,0.3)",
  s_extralight: "rgba(20,41,69,0.1)",
  s_ultralight: "rgba(20, 41, 69, 0.05)",
  s_superlight: "rgba(20,41,69,0.03)",
  transparent: "transparent",
  white: "#FFFFFF",
  white1: "#dedede",
  white2: "#e7e7e7",
  black: "#000000",
  half_black: "rgba(0,0,0,0.5)",
  shadow: "rgba(44, 137, 255, 0.1)",
  palette: [
    "#000000",
    "#FFFFFF",
    "#A766FA",
    "#5269DA",
    "#147DE8",
    "#24CFCF",
    "#EAF1FA",
    "#4cd965",
    "#FECC2F",
    "#FD9028",
    "#FF026B",
    "#D8D8D8",
    "#243F43",
    "#EA7A76",
    "#369BA5",
    "#F65D95",
    "#FF1716",
    "#62BCF9",
    "#FDE371",
    "#7FE7C2",
    "#F088E6",
    "#FF4149",
    "#1502F8",
    "#FFFD54",
  ],

  tools_slider: {
    video_primary: "#FF9C34",
    video_secondary: "#FBE7D4",
    video_background: "rgba(255, 156, 52, 0.15)",
    audio_primary: "#5BB85D",
    audio_secondary: "#DBEEDD",
    audio_background: "rgba(91,184,93, 0.15)",
    layerBorder: "rgba(187, 187, 187, 0.3)",
    trackBackground: "rgba(0, 191, 255,0.25)",
  },
  shape_tools: {
    blue1: "#6dbbff",
    blue2: "#007bff",
    blue3: "#0091FF",
    red: "#dc3545",
    border: "#ABBEDF",
  },
  speed_tools: {
    border: "#00c1ff",
  },
  text_tools: {
    caret: "#0091ff",
    border: "#ABBEDF",
  },
  palette_tools: {
    font: "#00c1ff",
    border: "#979797",
    secondary: "rgba(0, 61, 162, 1)",
    s_light: "rgba(0, 61, 162, 0.2)",
    s_verylight: "rgba(0, 61, 162, 0.1)",
  },
};

export const fontSize = {
  xxsmall: "8px",
  xsmall: "10px",
  small: "12px",
  medium: "13px",
  large: "14px",
  xlarge: "16px",
  xxlarge: "20px",
};

export const fontWeight = {
  lighter: 300,
  bold: 400,
  bolder: 600,
  bigger: 700,
};

export const icons = {
  IC_Banner,
  IC_Triangle,
  IC_Pentagon,
  IC_Star,
  IC_Delete,
  IC_Add_Layer,
  IC_Video,
  IC_Video_Blue,
  IC_Color_Correction,
  IC_Color_Correction_Blue,
  IC_Contrast,
  IC_Contrast_Blue,
  IC_Effects,
  IC_Effects_Blue,
  IC_Text,
  IC_Text_Blue,
  IC_Draw,
  IC_Draw_Blue,
  IC_Shape,
  IC_Shape_Blue,
  IC_Speed,
  IC_Speed_Blue,
  IC_EyeOpen,
  IC_EyeClose,
  IC_DeleteIcon,
  IC_AudioSectionPng,
  IC_VideoSectionPng,
  IC_Tick,
  IC_Cancel,
  IC_Audio,
  IC_Audio_Blue,
  IC_Magnify,
  IC_Magnify_Blue,
  IC_Printer,
  IC_Plus,
  IC_Bin,
  IC_Keyboard,
  IC_KeyboardWhite,
  IC_RightArrow,
  IC_LeftArrow,
  IC_Undo,
  IC_Redo,
  IC_Hamburger,
  IC_Add_Comment,
  IC_BLue_Tick,
  Suspect_Icon_Dark,
  IC_Edit,
  IC_Share,
};
