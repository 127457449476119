const keys = {
   SERVER_URL: `https://${window.location.hostname}/api/v1`,
//  SERVER_URL: `http://${window.location.hostname}:4050/api/v1`,
  AzureStorageAccount: "suspecttechprod",
  resultDimensions: { height: 500, width: 750 },
  licenseTypes: {
    redaction: "redaction",
    facesearch: "facesearch",
  },
};

export default keys;
