import React from "react";
import Dropdown, { MenuItem } from "../SelectDropdown/Dropdown";
import PropTypes from "prop-types";

import styled from "styled-components";

const CustomDropdown = styled(Dropdown)`
  border-radius: 3px;
  width: 100%;
  button {
    border-color: #edeff2 !important;
  }
`;

CustomDropdown.propTypes = Dropdown.propTypes;
CustomDropdown.defaultProps = Dropdown.defaultProps;

const CustomDropdownToggle = styled("select")`
  height: 4.8rem;
  width: 100%;
  padding: 0 1rem;
  border: 1px solid #edeff2;
  border-radius: 3px;
  color: #142945;
  font-family: "Muli", sans-serif;
  font-size: 1.6rem;
  line-height: 2rem;
  display: flex;
  justify-content: center;
`;

CustomDropdownToggle.propTypes = Dropdown.Toggle.propTypes;
CustomDropdownToggle.defaultProps = Dropdown.Toggle.defaultProps;

const CustomDropdownMenu = styled(Dropdown.Menu)`
  border-radius: 3px !important;
  width: 100%;
  max-height: 30rem;
  overflow-y: auto;
  border: none !important;
  padding: 0 !important;
  margin: 0;
`;

CustomDropdownMenu.propTypes = Dropdown.Menu.propTypes;
CustomDropdownMenu.defaultProps = Dropdown.Menu.defaultProps;

const Customhr = styled.hr`
  padding: 0;
  border: 1px solid #edeff2;
  margin: 0px;
  height: 1px;
  background-color: #edeff2;
  display: block;
`;

const CustomMenuItem = styled(MenuItem)`
  height: 4.8rem;
  width: 100%;
  color: #223539;
  font-family: "Muli", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;
  div:first-child {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
  }
`;

CustomMenuItem.propTypes = MenuItem.propTypes;
CustomMenuItem.defaultProps = MenuItem.defaultProps;

const TextSelect = (props) => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          opacity: "0.5",
          lineHeight: "3rem",
          fontSize: "1.4rem",
          display: "block",
          fontFamily: '"Muli", sans-serif',
        }}
      >
        {props.label}
      </div>

      <CustomDropdownToggle
        value={props.selectedOption}
        onChange={(e) => {
          props.handleSelect(e.target.value);
        }}
      >
        {props.options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </CustomDropdownToggle>

      {/* 
      <CustomDropdown onSelect={(eventKey) => {}}>
        <CustomDropdownToggle noCaret btnStyle="flat">
          {props.selectedOption !== "" && props.selectedOption
            ? props.selectedOption
            : "-Select-"}
          <span style={{ float: "right" }}>&#9662;</span>
        </CustomDropdownToggle>
        <CustomDropdownMenu>
          {props.options.map((option, i) => (
            <CustomMenuItem
              key={i}
              eventKey={option}
              onSelect={(eventKey) => props.handleSelect(option)}
            >
              <div>{option}</div>
              {props.options.length - 1 !== i ? <Customhr /> : null}
            </CustomMenuItem>
          ))}
        </CustomDropdownMenu>
      </CustomDropdown> */}
    </div>
  );
};

export default TextSelect;

TextSelect.propTypes = {
  options: PropTypes.array,
  handleSelect: PropTypes.func,
  selectedOption: PropTypes.string,
};
