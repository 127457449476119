import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class Home extends Component {
  render() {
    const { pathname } = this.props.location;
    const { isAuthenticated } = this.props;

    if (pathname === "/" && !isAuthenticated) {
      return <Redirect to="/signin" />;
    }

    return <Redirect to="/facesearch" />;
  }
}
const mapStateToProps = ({ user }) => ({
  isAuthenticated: user.isAuthenticated,
});

export default connect(mapStateToProps, null)(Home);
