import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-decoration: none;
    color: ${(props) => props.fontColor};
    pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  }
`;

const IconWrapper = styled.div`
  padding-bottom: 5px;

  img {
    width: ${(props) => props.iconWidth};
  }
`;

const LinkItem = ({
  src,
  label,
  fontColor,
  icon,
  isActive,
  disabled,
  iconWidth,
}) => (
  <Wrapper fontColor={fontColor} isActive={isActive} disabled={disabled}>
    <Link to={src}>
      <IconWrapper iconWidth={iconWidth}>
        <img src={icon} alt={label} />
      </IconWrapper>
      <div>{label}</div>
    </Link>
  </Wrapper>
);

export default LinkItem;

LinkItem.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  fontColor: PropTypes.string,
  iconWidth: PropTypes.string,
};

LinkItem.defaultProps = {
  fontColor: "#FFFFFF",
  isActive: false,
  iconWidth: "25px",
};
