import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Redirect, withRouter } from "react-router-dom";

import Background from "../../components/Form/Background";
import Button from "../../components/Form/Button";
import Input from "../../components/common/Form/MaterialInput";
import loader from "../../assets/img/loader.svg";
import makeRequest from "../../config/helpers";

import {
  successNotification,
  errorNotification,
  warningNotification,
} from "../../actions";
import { colors } from "../../components/themes/base";

const Wrapper = styled.div`
  display: flex;
  div:first-child {
    @media only screen and (min-width: ${(props) => props.mdBreakpoint}) {
      width: 68vw;
    }
    @media only screen and (max-width: ${(props) => props.mdBreakpoint}) {
      display: none;
    }
  }
  div:last-child {
    @media only screen and (max-width: ${(props) => props.mdBreakpoint}) {
      width: 70vw;
    }
  }

  .login-wrapper {
    background: ${(props) => props.background};
    width: 20vw;
    height: 100vh;
    font-family: ${(props) => props.fontFamily};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: ${(props) => props.margin};
    div:first-child {
      @media only screen and (max-width: ${(props) => props.mdBreakpoint}) {
        width: 70vw;
      }
    }

    .title {
      max-width: 100%;
      color: #142945;
      display: block !important;
      font-weight: 600;
      font-size: 3rem;
      margin-bottom: 5rem;
    }

    a {
      color: #133a7b;
      cursor: pointer;
      font-size: 1.4rem;
    }

    .copyright {
      margin-top: 6.3rem;
      font-size: 1.2rem;
      color: #142945;
      opacity: 0.3;
    }
  }
`;

class Invite extends Component {
  state = {
    email: "",
    loading: false,
    error: null,
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleInvite = async () => {
    if (!this.state.email) {
      this.props.warningNotification("Email cannot be empty");
    }

    this.setState({ loading: true });
    const res = await makeRequest("POST", "/users", null, {
      email: this.state.email,
    });
    this.setState({ loading: false });
    if (res.status === 200) {
      this.props.successNotification({ title: "Successfully invited user" });
      this.props.history.goBack();
    } else {
      this.props.errorNotification({ title: "Failed to invite user" });
    }
  };

  render() {
    if (
      !this.props.user ||
      this.props.user.email !== process.env.REACT_APP_ADMIN
    ) {
      return <Redirect to="/" />;
    }

    return (
      <Wrapper {...this.props.styles}>
        <div className="backgroundWrapper">
          <Background />
        </div>
        <div className="login-wrapper">
          <div className="title">User's Email to invite</div>
          <Input
            label="Email Address"
            placeholder="Email Address"
            name="email"
            handleChange={this.onChange}
            value={this.state.email}
          />

          <Button
            disabled={this.state.loading}
            background={colors.primary}
            color="#fff"
            onClick={this.handleInvite}
            textTransform="none"
            fontSize="1.8rem"
            fontWeight="normal"
          >
            Invite&nbsp;&nbsp;
            {this.state.loading ? <img src={loader} alt="loader" /> : null}
          </Button>
          <div className="copyright">
            &copy;2023 All Rights Reserved. Suspect Technologies&reg; Ltd.
            <br />
          </div>
        </div>
      </Wrapper>
    );
  }
}

Invite.defaultProps = {
  styles: {
    width: "30rem",
    background: "#FFFFFF",
    fontFamily: '"Muli", sans-serif',
    margin: "auto",
    mdBreakpoint: "900px",
  },
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  user: state.user.user,
});

const InviteWithRouter = withRouter(Invite);

export default connect(mapStateToProps, {
  successNotification,
  errorNotification,
  warningNotification,
})(InviteWithRouter);
